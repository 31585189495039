<template>
    <section id="blog">
        <div class="container">
            <h2>Learn more about Fantoon</h2>
            <h4>WE HELP MAKE THE DREAMS OF EVERY CREATOR COME TRUE WITH THE HELP OF OUR COMMUNITY!</h4>

            <div class="row mt-5">
                <div class="col-12 col-md-6 col-lg-4 mt-4">
                    <aside>
                        <span class="demo4"></span>
                        <div class="inner p-3">
                            <div class="">
                                <h3>What are Diamonds? How do holders benefit from Fantoon?</h3>
                                <p class="text-justify">Diamond tokens are the rare, precious backbone of Fantoon. Holders of diamond tokens enjoy multiple revenue streams from Fantoon. Find more details about diamonds and the founders club <a href="https://discord.gg/Gj7JqN2mXZ">HERE</a>.</p>
                            </div>
                        </div>
                    </aside>
                </div>

                <div class="col-12 col-md-6 col-lg-4 mt-4">
                    <aside>
                        <span class="demo2"></span>
                        <div class="inner p-3">
                            <div class="">
                                <h3>How do you apply to Fantoon to launch your project?</h3>
                                <p class="text-justify">Simply send us details about who you are, what your project is, why you’re creating the project (or why’s it relevant), and the community will review it and get back to you. Apply <a href="https://discord.gg/Gj7JqN2mXZ">HERE</a>.</p>
                            </div>
                        </div>
                    </aside>
                </div>

                <div class="col-12 col-lg-4 mt-4">
                    <aside>
                        <span class="demo3"></span>
                        <div class="inner p-3">
                            <div class="">
                                <h3>How is Fantoon connected to Pumpkitten?</h3>
                                <p class="text-justify">The idea of Fantoon came from the Pumpkinland marketplace, a place for the proposed GameFi to buy/sell and trade NFT elements. But as development began, it's evolved into much more, <a href="https://docs.pumpkitten.com/fantoon/fantoon-nft-launchpad">read more..</a></p>
                            </div>
                        </div>
                    </aside>
                </div>

            </div>
        </div>

    </section>  
</template>
<script>
    export default {
        data () {
            return {}
        }
    }
</script>